.Container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: flex-start;
    /*justify-content: space-evenly;*/
}

.Content {
    display: flex;
    flex-direction: column;
}

@media (max-width: 1037px) {
    .Content {
        flex-grow: 2;
    }
}

.Content h4 {
    margin: 0;
}

.tryPixel img {
    width: 402px;
}

.tryPixel:hover {
    -webkit-filter: drop-shadow(5px 5px 5px #d157ef);
    filter: drop-shadow(5px 5px 10px #d157ef);
}

.ChampName {
    align-self: center;
    margin: 0 0 0 10%;
}