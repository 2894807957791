@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
body {
  margin: 0;
  padding: 0;
  font-family: 'bignoodletitling', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  /* font-family: 'Nunito Sans', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif; */
  background-image: url(/static/media/bg7.48eff05a.webp);
  position: relative;
  overflow-x: hidden;
  background-color: #151c2e;
  letter-spacing: 1px;
  height: 100%;
  margin: 0;
  padding: 0;
}

body .about {
  background-image: url(/static/media/bg7.48eff05a.webp);
  overflow-x: hidden;
  overflow-y: visible;
}
h1 {
  color: #919aa1;
  width: 50%;
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

h2 {
  color: #919aa1;
  width: 100%;
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
}

h6 {
  color: #919aa1;
  width: 100%;
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
}

.Footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  text-align: right;
  color: #919aa1;
}

.Footer a {
  text-decoration: none;
}

.Loader_Loader__17sQZ{
    margin-left:10%
}
.Canvas_Canvas__2a1q7 {
    display: none;
}

.Canvas_GeneratedImage__2yXgN{
    margin-left:10%
}
.PixelMixer_Container__3TutL {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: flex-start;
    /*justify-content: space-evenly;*/
}

.PixelMixer_Content__3F5HD {
    display: flex;
    flex-direction: column;
}

@media (max-width: 1037px) {
    .PixelMixer_Content__3F5HD {
        flex-grow: 2;
    }
}

.PixelMixer_Content__3F5HD h4 {
    margin: 0;
}

.PixelMixer_tryCute__22Vba img {
    width: 402px;
}

.PixelMixer_tryCute__22Vba:hover {
    filter: drop-shadow(5px 5px 10px #d157ef);
}

.PixelMixer_ChampName__37c2m {
    align-self: center;
    margin: 0 0 0 10%;
}
.MixerControl_ControlGroup__2O6Vv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.MixerControl_ControlGroup__2O6Vv label {
  width: 35%;
  align-self: center;
}
.MixerControl_ControlGroup__2O6Vv > div {
  /* flex-grow: 2;
  width: 56%;
  margin-left: 10px; */
  width: 39%;
}

.MixerControl_ControlGroup__2O6Vv button {
  width: 22%;
  margin-left: 10px;
}

.MixerControls_MixerControlContainer__1GxhE {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /*justify-content: space-evenly;*/
  flex-basis: 27%;
  /*margin-top: 6%;*/
  min-width: 500px;
}

@media (max-width: 1037px) {
  .MixerControls_MixerControlContainer__1GxhE {
    margin-top: 0;
    flex-basis: unset;
    justify-content: center;
    flex-grow: 2;
    align-self: center;
  }
}

.MixerControls_MixerControlContainer__1GxhE > * {
  /*flex-grow: 0;*/
  /*flex-shrink: 0;*/
  /*flex-basis: 5%;*/
  line-height: 3em;
  margin: 5px;
  height: 50px;
}

.MixerControls_upvoted__1sJG2 {
  color: greenyellow;
}
.MixerControls_copyButton__2cGbQ {
  margin-top: 10px !important;
}

.CuteMixer_Container__b5CRB {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: flex-start;
    /*justify-content: space-evenly;*/
}

.CuteMixer_Content__-Xy3P {
    display: flex;
    flex-direction: column;
}

@media (max-width: 1037px) {
    .CuteMixer_Content__-Xy3P {
        flex-grow: 2;
    }
}

.CuteMixer_Content__-Xy3P h4 {
    margin: 0;
}

.CuteMixer_tryPixel__3H4EB img {
    width: 402px;
}

.CuteMixer_tryPixel__3H4EB:hover {
    filter: drop-shadow(5px 5px 10px #d157ef);
}

.CuteMixer_ChampName__1gJJ6 {
    align-self: center;
    margin: 0 0 0 10%;
}
.Card_Card__2v91Y {
  flex: 1 1;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
  word-wrap: break-word;
  margin: 1vh;
  cursor: pointer;
  /*margin: 5em 0 5em 5em;*/
  /*width: 20%;*/
  box-shadow: 0 8px 16px 0 rgba(209, 87, 239, 0.2);
  transition: 0.3s;
  border-radius: 10px; /* 5px rounded corners */
  min-width: 450px;
  text-decoration: none;
}

.Card_Card__2v91Y img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.Card_Card__2v91Y a {
  text-decoration: none;
}

.Card_Card__2v91Y:hover {
  box-shadow: 0 16px 168px 0 rgba(209, 87, 239, 0.2);
}

.Card_Card__2v91Y:focus {
  border-width: 4px;
}

.Card_Card__2v91Y:focus,
.Card_Card__2v91Y:hover {
  z-index: 5;
  text-decoration: none;
}

.Card_Card__2v91Y h1 {
  text-decoration: none;
  margin-top: 20px;
  width: 100%;
}

.Card_Card__2v91Y p {
  text-decoration: none;
  text-align: center;
  color: #d157ef;
}

.Home_Home__A2KpE{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
/* #f38a2c that orange */
/* #7795b1 some blue? */

/* triangles colors

#F38A2C
#E2EEF4
#72AFC5
#5C7E9C
#34273B

*/

/*  Extracted dominant colors from blurry battlefield image
*/
.Links_dominant__8hZSr {
  color: #180e11;
  color: #e2ecf1;
  color: #99acc1;
  color: #b8ccdd;
  color: #2f272f;

  color: #7d8fa4;

  color: #414757;
  color: #617186;

  color: #cf9a78;
  color: #8a543f;
}
/* Used https://trianglify.netlify.app/ with: */
/*  #f38a2c,#e2eef4,#72afc5,#5c7e9c,#34273b,#2f272f,#e2ecf1,#99acc1 */

.Links_linkBody__1eL-4 {
  background: #151c2e;
  animation: Links_fade-in__1TBsP 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  /* webp was not crisp enough */
  background-image: -webkit-image-set(
    /* url('../../assets/images/linkBG_1_1_760.webp') type('image/webp'), */
      url(/static/media/linkBG_1_1_760.654205dc.png) type('image/png')
  );
  background-image: image-set(
    
      url(/static/media/linkBG_1_1_760.654205dc.png) type('image/png')
  );

  /* Medium screens (4:3 aspect ratio) */
  @media (min-width: 768px) {
    background-image: -webkit-image-set(
      /* url('../../assets/images/linkBG_4_3_957.webp') type('image/webp'), */
        url(/static/media/linkBG_4_3_957.2998e17f.png) type('image/png')
    );
    background-image: image-set(
      
        url(/static/media/linkBG_4_3_957.2998e17f.png) type('image/png')
    );
  }

  /* Large screens (16:9 aspect ratio) */
  @media (min-width: 992px) {
    background-image: -webkit-image-set(
      /* url('../../assets/images/linkBG_16_9_1207.webp') type('image/webp'), */
        url(/static/media/linkBG_16_9_1207.be5c02d3.png) type('image/png')
    );
    background-image: image-set(
      
        url(/static/media/linkBG_16_9_1207.be5c02d3.png) type('image/png')
    );
  }

  /* Extra large screens (16:9 aspect ratio, higher resolution) */
  @media (min-width: 1200px) {
    background-image: -webkit-image-set(
      /* url('../../assets/images/linkBG_16_9_1440.webp') type('image/webp'), */
        url(/static/media/linkBG_16_9_1440.a06c134c.png) type('image/png')
    );
    background-image: image-set(
      
        url(/static/media/linkBG_16_9_1440.a06c134c.png) type('image/png')
    );
  }

  /* Ultra-wide screens (4K resolution) */
  @media (min-width: 1600px) {
    background-image: -webkit-image-set(
      /* url('../../assets/images/linkBG_3840.webp') type('image/webp'), */ url(/static/media/linkBG_3840.62ce7620.png)
        type('image/png')
    );
    background-image: image-set(
       url(/static/media/linkBG_3840.62ce7620.png)
        type('image/png')
    );
  }
}

.Links_socialIconRebuild__sYwOI {
  height: 80px;
  width: 80px;
  float: left;

  margin-top: -12px;
  border-radius: 50%;
}
.Links_socialIconRebuild__sYwOI img {
  padding: 15px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.Links_SocialIconList__bEewZ {
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  /* text-align: center; */
  clear: both;
  margin-bottom: 12px;
  max-width: 420px;
  width: auto;
}
.Links_userArea__3bd8o {
  text-align: center;
  margin-top: 20px;
  padding-top: 15px;
  margin-bottom: 25px;
}
.Links_imgCircle__2_c54 {
  position: relative;
  width: 96px;
  height: 96px;
  display: block;
  background-color: #00000019.8;
  margin: 0 auto 10px;
  border-radius: 50%;
  overflow: hidden;
}
.Links_userImg__17XBZ {
  width: 0;
  min-width: 96px;
  min-height: 96px;
  vertical-align: middle;
  border-style: none;
}
.Links_anchor__3X2jX {
  display: block;
  border-top-left-radius: 27px;
  border-bottom-left-radius: 27px;
  text-decoration: none;
  margin-bottom: 30px;
  height: 58px;
  animation: Links_scale-in-ver-top__19ZTQ 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  min-width: 350px;
  /* border: 2px solid #f38a2c;   */
  /* color: white; */
}

@media (max-width: 500px) {
  .Links_anchor__3X2jX {
    min-width: 325px;
  }
}

.Links_buttonWrapper__157qB {
}
.Links_inlineButton__ve874 {
  display: inline-block;
}
.Links_linkText__3Bb5_ {
  display: inline-block;
  color: white;
  text-align: center;
  top: -20px;
  left: 48px;
  position: relative;
  text-transform: uppercase;
  font-size: 54px;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-family: 'bignoodletitling';
}

.Links_linkText--loading__VDIIt {
  left: 7px;
  font-family: sans-serif;
  text-transform: unset;
}

@media (max-width: 420px) {
  .Links_linkText__3Bb5_ {
    left: 8px;
  }
}

@font-face {
  font-family: 'bignoodletitling';
  src: url(/static/media/big_noodle_titling.f77d2487.ttf);
  font-size: 50px;
  font-display: swap;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

/* .scaleInVerticalTop {
  -webkit-animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
} */

/* ----------------------------------------------
 * Generated by Animista on 2020-2-21 21:41:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-ver-top
 * ----------------------------------------
 */
@keyframes Links_scale-in-ver-top__19ZTQ {
  0% {
    transform: scaleY(0);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-2-21 22:9:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes Links_fade-in__1TBsP {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Layout_container__k6iZp {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

.Layout_Content__365f3 {
  margin-top: 136px;
}

.Layout_Content__365f3 > * {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  padding: 0 10px 10px 10px;
  text-align: center;
  color: #919aa1;
}

.Toolbar_Toolbar__ApScI {
    height: 136px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #1a1a1a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 16px;
    box-sizing: border-box;
    z-index: 90;

}

.Toolbar_Toolbar__ApScI nav {
    display: flex;
    width: 100%;
}

@media (max-width: 1037px) {
    .Toolbar_DesktopOnly__LuPaL {
        display: none;
    }
    .Toolbar_Toolbar__ApScI nav {
        display: none;
    }
}
.Logo_Logo__1N0xH {
    height: 80px;
    width: 80px;
    vertical-align: middle;
}
.Logo_LogoAnchor__3RA0e{
    display: inline-block;
    padding-top: .25rem;
    padding-bottom: .25rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
}
.DrawerToggle_DrawerToggle__m405X {
    width: 50px;
    height: 80%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.DrawerToggle_DrawerToggle__m405X div {
    width: 90%;
    height: 3px;
    background-color: white;
}

@media (min-width: 1037px) {
    .DrawerToggle_DrawerToggle__m405X {
        display: none;
    }
}
.NavigationItems_NavigationItems__1fnFX {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.875rem;
}

@media (min-width: 1037px) {
    .NavigationItems_NavigationItems__1fnFX {
        flex-flow: row;
    }
}
.NavigationItem_NavigationItem__2SpXc {
    box-sizing: border-box;
    display: block;
    width: 100%;

}

.NavigationItem_NavigationItem__2SpXc a {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    margin-left: 3rem;

}

.NavigationItem_NavigationItem__2SpXc a img {
    width: 44px;
}

.NavigationItem_NavigationItem__2SpXc a:hover,
.NavigationItem_NavigationItem__2SpXc a:active,
.NavigationItem_NavigationItem__2SpXc a.NavigationItem_active__2v2td {
    color: #ffffff;
}

@media (min-width: 1037px) {
    .NavigationItem_NavigationItem__2SpXc {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }

    .NavigationItem_NavigationItem__2SpXc a {
        color: rgba(255, 255, 255, 0.5);
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }

    .NavigationItem_NavigationItem__2SpXc a:hover,
    .NavigationItem_NavigationItem__2SpXc a:active,
    .NavigationItem_NavigationItem__2SpXc a.NavigationItem_active__2v2td {
        border-bottom: 4px solid #40A4C8;
        color: white;
    }
}
.SideDrawer_SideDrawer__3kXLK {
    position: fixed;
    width: 225px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    background-color: #1a1a1a;
}
.SideDrawer_SideDrawerNav__2wW6y ul li {
    margin-bottom: 50px;
}
@media (min-width: 1037px) {
    .SideDrawer_SideDrawer__3kXLK {
        display: none;
    }
}

.SideDrawer_Open__1tCv1 {
    transform: translateX(0);
}

.SideDrawer_Close__9j7x- {
    transform: translateX(-100%);
}

.SideDrawer_Logo__3voUv {
    height: 11%;
    margin-bottom: 32px;
}
.Backdrop_Backdrop__11Pxb {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
