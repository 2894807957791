.NavigationItem {
    box-sizing: border-box;
    display: block;
    width: 100%;

}

.NavigationItem a {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    margin-left: 3rem;

}

.NavigationItem a img {
    width: 44px;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    color: #ffffff;
}

@media (min-width: 1037px) {
    .NavigationItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }

    .NavigationItem a {
        color: rgba(255, 255, 255, 0.5);
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }

    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active {
        border-bottom: 4px solid #40A4C8;
        color: white;
    }
}