.container {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

.Content {
  margin-top: 136px;
}

.Content > * {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  padding: 0 10px 10px 10px;
  text-align: center;
  color: #919aa1;
}
