.Toolbar {
    height: 136px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #1a1a1a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 16px;
    box-sizing: border-box;
    z-index: 90;

}

.Toolbar nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}

@media (max-width: 1037px) {
    .DesktopOnly {
        display: none;
    }
    .Toolbar nav {
        display: none;
    }
}