.ControlGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ControlGroup label {
  width: 35%;
  align-self: center;
}
.ControlGroup > div {
  /* flex-grow: 2;
  width: 56%;
  margin-left: 10px; */
  width: 39%;
}

.ControlGroup button {
  width: 22%;
  margin-left: 10px;
}
