.MixerControlContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /*justify-content: space-evenly;*/
  flex-basis: 27%;
  /*margin-top: 6%;*/
  min-width: 500px;
}

@media (max-width: 1037px) {
  .MixerControlContainer {
    margin-top: 0;
    flex-basis: unset;
    justify-content: center;
    flex-grow: 2;
    align-self: center;
  }
}

.MixerControlContainer > * {
  /*flex-grow: 0;*/
  /*flex-shrink: 0;*/
  /*flex-basis: 5%;*/
  line-height: 3em;
  margin: 5px;
  height: 50px;
}

.upvoted {
  color: greenyellow;
}
.copyButton {
  margin-top: 10px !important;
}
