.Logo {
    height: 80px;
    width: 80px;
    vertical-align: middle;
}
.LogoAnchor{
    display: inline-block;
    padding-top: .25rem;
    padding-bottom: .25rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
}