/* #f38a2c that orange */
/* #7795b1 some blue? */

/* triangles colors

#F38A2C
#E2EEF4
#72AFC5
#5C7E9C
#34273B

*/

/*  Extracted dominant colors from blurry battlefield image
*/
.dominant {
  color: #180e11;
  color: #e2ecf1;
  color: #99acc1;
  color: #b8ccdd;
  color: #2f272f;

  color: #7d8fa4;

  color: #414757;
  color: #617186;

  color: #cf9a78;
  color: #8a543f;
}
/* Used https://trianglify.netlify.app/ with: */
/*  #f38a2c,#e2eef4,#72afc5,#5c7e9c,#34273b,#2f272f,#e2ecf1,#99acc1 */

.linkBody {
  background: #151c2e;

  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  /* webp was not crisp enough */
  background-image: image-set(
    /* url('../../assets/images/linkBG_1_1_760.webp') type('image/webp'), */
      url('../../assets/images/linkBG_1_1_760.png') type('image/png')
  );

  /* Medium screens (4:3 aspect ratio) */
  @media (min-width: 768px) {
    background-image: image-set(
      /* url('../../assets/images/linkBG_4_3_957.webp') type('image/webp'), */
        url('../../assets/images/linkBG_4_3_957.png') type('image/png')
    );
  }

  /* Large screens (16:9 aspect ratio) */
  @media (min-width: 992px) {
    background-image: image-set(
      /* url('../../assets/images/linkBG_16_9_1207.webp') type('image/webp'), */
        url('../../assets/images/linkBG_16_9_1207.png') type('image/png')
    );
  }

  /* Extra large screens (16:9 aspect ratio, higher resolution) */
  @media (min-width: 1200px) {
    background-image: image-set(
      /* url('../../assets/images/linkBG_16_9_1440.webp') type('image/webp'), */
        url('../../assets/images/linkBG_16_9_1440.png') type('image/png')
    );
  }

  /* Ultra-wide screens (4K resolution) */
  @media (min-width: 1600px) {
    background-image: image-set(
      /* url('../../assets/images/linkBG_3840.webp') type('image/webp'), */ url('../../assets/images/linkBG_3840.png')
        type('image/png')
    );
  }
}

.socialIconRebuild {
  height: 80px;
  width: 80px;
  float: left;

  margin-top: -12px;
  border-radius: 50%;
}
.socialIconRebuild img {
  padding: 15px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.SocialIconList {
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  /* text-align: center; */
  clear: both;
  margin-bottom: 12px;
  max-width: 420px;
  width: auto;
}
.userArea {
  text-align: center;
  margin-top: 20px;
  padding-top: 15px;
  margin-bottom: 25px;
}
.imgCircle {
  position: relative;
  width: 96px;
  height: 96px;
  display: block;
  background-color: #00000019.8;
  margin: 0 auto 10px;
  border-radius: 50%;
  overflow: hidden;
}
.userImg {
  width: 0;
  min-width: 96px;
  min-height: 96px;
  vertical-align: middle;
  border-style: none;
}
.anchor {
  display: block;
  border-top-left-radius: 27px;
  border-bottom-left-radius: 27px;
  text-decoration: none;
  margin-bottom: 30px;
  height: 58px;
  -webkit-animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  min-width: 350px;
  /* border: 2px solid #f38a2c;   */
  /* color: white; */
}

@media (max-width: 500px) {
  .anchor {
    min-width: 325px;
  }
}

.buttonWrapper {
}
.inlineButton {
  display: inline-block;
}
.linkText {
  display: inline-block;
  color: white;
  text-align: center;
  top: -20px;
  left: 48px;
  position: relative;
  text-transform: uppercase;
  font-size: 54px;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-family: 'bignoodletitling';
}

.linkText--loading {
  left: 7px;
  font-family: sans-serif;
  text-transform: unset;
}

@media (max-width: 420px) {
  .linkText {
    left: 8px;
  }
}

@font-face {
  font-family: 'bignoodletitling';
  src: url('../../assets/fonts/big_noodle_titling.ttf');
  font-size: 50px;
  font-display: swap;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

/* .scaleInVerticalTop {
  -webkit-animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
} */

/* ----------------------------------------------
 * Generated by Animista on 2020-2-21 21:41:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-ver-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-2-21 22:9:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
