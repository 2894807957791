.Card {
  flex: 1;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
  word-wrap: break-word;
  margin: 1vh;
  cursor: pointer;
  /*margin: 5em 0 5em 5em;*/
  /*width: 20%;*/
  box-shadow: 0 8px 16px 0 rgba(209, 87, 239, 0.2);
  transition: 0.3s;
  border-radius: 10px; /* 5px rounded corners */
  min-width: 450px;
  text-decoration: none;
}

.Card img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.Card a {
  text-decoration: none;
}

.Card:hover {
  box-shadow: 0 16px 168px 0 rgba(209, 87, 239, 0.2);
}

.Card:focus {
  border-width: 4px;
}

.Card:focus,
.Card:hover {
  z-index: 5;
  text-decoration: none;
}

.Card h1 {
  text-decoration: none;
  margin-top: 20px;
  width: 100%;
}

.Card p {
  text-decoration: none;
  text-align: center;
  color: #d157ef;
}
