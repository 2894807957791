@import url('https://fonts.googleapis.com/css?family=Nunito+Sans');
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  /* font-family: 'Nunito Sans', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif; */
  background-image: url('./assets/images/bg7.webp');
  position: relative;
  overflow-x: hidden;
  background-color: #151c2e;
  letter-spacing: 1px;
  height: 100%;
  margin: 0;
  padding: 0;
}

body .about {
  background-image: url('./assets/images/bg7.webp');
  overflow-x: hidden;
  overflow-y: visible;
}
h1 {
  color: #919aa1;
  width: 50%;
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

h2 {
  color: #919aa1;
  width: 100%;
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
}

h6 {
  color: #919aa1;
  width: 100%;
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
}

.Footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  text-align: right;
  color: #919aa1;
}

.Footer a {
  text-decoration: none;
}
