.NavigationItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.875rem;
}

@media (min-width: 1037px) {
    .NavigationItems {
        flex-flow: row;
    }
}